import { useEffect, useRef, useState } from 'react';
import './App.css';
// import AzureDictaphone, { state } from './components/Dictaphone';
import loadingGif from './assets/loading.gif'
import staticGif from './assets/static.gif'
import activeGif from './assets/active.gif'

// import { useSpeechRecognition } from 'react-speech-recognition';
import { Bot, MessageSquare, Mic, SendHorizontal, StopCircle, User } from 'lucide-react';
import { socket } from './utils/socket';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { SpeechConfig, AudioConfig, SpeechRecognizer, ResultReason, CancellationReason } from 'microsoft-cognitiveservices-speech-sdk';
import { ConversationMessage } from './components/Message';
import useUserUUID from './utils/hooks';
import Sidebar from './components/Sidebar';
import { fetchMessagesForSession } from './utils/api';


export type state = 'audio_playing' | 'mic_active' | 'default' | 'loading' | 'error';

export interface Message {
  role: 'user' | 'assistant',
  content: string
}


const LoadingMessage = () => (
  <div className="flex mb-6 justify-start">
    <div className="flex items-start space-x-2 max-w-[80%]">
      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#0096D6] text-white">
        <Bot className="w-5 h-5" />
      </div>
      <div className="bg-white border border-gray-200 rounded-2xl p-4 shadow-sm">
        <div className="mt-2 flex space-x-1">
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
        </div>
      </div>
    </div>
  </div>
);


function WelcomePage({ action }: { action: (mode: 'text' | 'voice') => void }) {
  return (
    <div className="min-h-screen bg-[#f2ebdc] flex flex-col">
      {/* <header className="bg-white shadow-sm">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          <img
            src={hpLogo}
            alt="HP Logo"
            width={40}
            height={40}
            className="w-auto h-6 sm:h-8"
          />
        </div>
      </header> */}

      <main className="flex-grow container mx-auto px-4 py-8 sm:py-12 flex flex-col items-center justify-center">
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-4 sm:mb-8 text-gray-800">Welcome to HP Engager</h1>
        <p className="text-lg sm:text-xl text-center mb-8 sm:mb-12 text-gray-600">
          Your AI assistant for information on HP Engage G2 Pro
        </p>

        <div className="mb-8 sm:mb-12">
          <img
            src={staticGif}
            alt="AI Intelligence Visualization"
            width={300}
            height={300}
            className="object-cover rounded-full w-48 h-48 sm:w-64 sm:h-64 md:w-80 md:h-80"
          />
        </div>

        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8 w-full sm:w-auto">
          <Button
            className="bg-[#ff6d00] hover:bg-[#ff6d00]/80  text-white px-6 py-3 rounded-full text-lg w-full sm:w-auto"
            onClick={() => action('text')}
          >
            <MessageSquare className="mr-2 h-5 w-5" /> Interact via Text
          </Button>

          <Button
            className="bg-[#ff6d00] hover:bg-[#ff6d00]/80  text-white px-6 py-3 rounded-full text-lg w-full sm:w-auto"
            onClick={() => action('voice')}
          >
            <Mic className="mr-2 h-5 w-5" /> Interact via Voice
          </Button>
        </div>
      </main>
    </div>
  )
}



function App() {

  const [showChat, setShowChat] = useState(false)


  const handleStart = (mode: 'text' | 'voice') => {
    if (mode === 'text') {
      setShowChat(true)
    } else {
      startListening()
      // const audio = new Audio("https://peregrine-results.s3.amazonaws.com/pigeon/NaRNcxVv6RytL6yyTE_0.mp3");
      // audio.play();
      setShowChat(true)
    }

  }

  const [audioSrc, setAudioSrc] = useState<string>('');
  // const [sessionToDelete, setSessionToDelete] = useState<string | null>(null)
  const sessionToDeleteRef = useRef<string | null>(null);

  const [activeTranscript, setActiveTranscript] = useState<Message | null>(null);
  const [activity, setActivity] = useState<'idle' | 'user_speaking' | 'loading' | 'responding'>('idle');
  const messagesEndRef = useRef<HTMLDivElement>(null);


  const userUUID = useUserUUID()
  console.log(sessionToDeleteRef.current, "outside");


  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const audioRef = useRef<HTMLAudioElement>(null);
  const inputRef = useRef<HTMLInputElement>(null)

  const getGifSrc = () => {
    switch (activity) {
      case 'idle':
        return staticGif;
      case 'user_speaking':
      case 'loading':
        return loadingGif;
      case 'responding':
        return activeGif;
      default:
        return staticGif;
    }
  };

  const sayPrompt = (prompt: string, sessionId: string) => {
    if (!audioRef.current) return;
    const onError = () => {
      setIsLoading(false);
      console.error('Error loading audio');
    };
    try {
      const audioElement = audioRef.current;
      audioElement.pause();
      audioElement.currentTime = 0;



      const searchParams = new URLSearchParams();
      searchParams.set('prompt', prompt);
      searchParams.set('socketId', socket.id || '')

      if (sessionId) {
        searchParams.set('id', sessionId);
      }
      console.log(sessionToDeleteRef.current, "inside");

      if (sessionToDeleteRef.current) {
        searchParams.set('move', sessionToDeleteRef.current);

      }

      setAudioSrc(`/speech?${searchParams.toString()}`);
      setIsLoading(true);


      audioElement.load();

      const playAudio = () => {
        audioElement.play();
        setIsLoading(false);
      };

      audioElement.addEventListener('loadeddata', playAudio);
      audioElement.addEventListener('error', onError);
      audioElement.addEventListener('play', () => setActivity('responding'));
      audioElement.addEventListener('ended', () => setActivity('idle'));
      // audioElement.addEventListener('ended', () => {
      //   listening
      //     ? setState('mic_active')
      //     : setState('default')
      // })


      return () => {
        audioElement.removeEventListener('loadeddata', playAudio);
        audioElement.removeEventListener('error', onError);
        audioElement.removeEventListener('play', () => setActivity('responding'))
        audioElement.removeEventListener('ended', () => setActivity('idle'));

        // audioElement.addEventListener('ended', () => {
        //   listening
        //     ? setState('mic_active')
        //     : setState('default')
        // })
      };
    } catch (error) {
      onError();
    }
  };

  const [recognizer, setRecognizer] = useState<SpeechRecognizer | null>(null);
  const recognizerRef = useRef<SpeechRecognizer | null>(null);
  const [isListening, setIsListening] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  useEffect(() => {
    try {
      //#TODO: HUGE SECURITY RISK
      const speechConfig = SpeechConfig.fromSubscription("2a98ad981b344bfebfdc30ae84e0d680", "eastus");
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const newRecognizer = new SpeechRecognizer(speechConfig, audioConfig);

      newRecognizer.recognizing = (s, e) => {
        console.log(`RECOGNIZING: Text=${e.result.text}`);
        if (e.result.text) {
          setActivity('user_speaking');
          setActiveTranscript({ role: 'user', content: e.result.text });
        }
      };

      newRecognizer.recognized = (s, e) => {
        if (e.result.reason === ResultReason.RecognizedSpeech) {
          if (e.result.text && socket.id) {
            console.log(`RECOGNIZED: Text=${e.result.text}`);
            setActiveTranscript(null);
            setMessages((prev) => [...prev, { role: 'user', content: e.result.text }]);
            sayPrompt(e.result.text, socket.id);
            setActivity('loading');
          }
        }
      };

      newRecognizer.canceled = (s, e) => {
        console.log(`CANCELED: Reason=${e.reason}`);
        if (e.reason === CancellationReason.Error) {
          console.log(`CANCELED: ErrorCode=${e.errorCode}`);
          console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
          setErrorMessage(`Error occurred: ${e.errorDetails}`);
        }
        setIsListening(false);
        setActivity('idle');
      };

      newRecognizer.sessionStopped = (s, e) => {
        console.log("Session stopped event.");
        setIsListening(false);
        setActivity('idle');
      };

      setRecognizer(newRecognizer);
      recognizerRef.current = newRecognizer;

      return () => {
        if (recognizerRef.current) {
          recognizerRef.current.close();
        }
      };
    } catch (error) {
      console.error('Error setting up recognizer:', error);
      setErrorMessage('Failed to set up speech recognition. Please check your microphone and try again.');
    }
  }, [socket.id])

  const startListening = async () => {
    if (recognizerRef.current) {
      try {
        await recognizerRef.current.startContinuousRecognitionAsync();
        setIsListening(true);
        setActivity('user_speaking');
        console.log('Recognition started');
      } catch (error) {
        console.error('Error starting recognition:', error);
        setErrorMessage('Failed to start speech recognition. Please try again.');
        setIsListening(false);
        setActivity('idle');
      }
    }
  };

  const stopListening = async () => {
    if (recognizerRef.current) {
      try {
        console.log('Stopping recognition...');
        await recognizerRef.current.stopContinuousRecognitionAsync();
        setIsListening(false);
        setActivity('idle');
        console.log('Recognition stopped');
      } catch (error) {
        console.error('Error stopping recognition:', error);
      }
    }
  };



  const [userInput, setUserInput] = useState<string>('')

  const [isLoading, setIsLoading] = useState(false);

  const [messages, setMessages] = useState<Message[]>([])


  useEffect(() => {
    inputRef.current?.focus()
    if (userUUID) {
      console.log(userUUID);
      socket.connect()
    }
    function onConnect() {
      socket.emit('setup', {
        userId: userUUID
      })
    }
    const onDisconnect = () => {
      console.log("disconnected");

    }
    socket.on('connect', onConnect);
    socket.on('reconnect', onConnect)
    socket.on('disconnect', onDisconnect);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('reconnect', onConnect)

    }
  }, [userUUID])


  useEffect(() => {

    socket.on('chat-ready', () => {
      setIsLoading(false);
      setActivity('idle');

    });



    socket.on('chat-response', (data: any) => {
      if (data.isFinished) {
        setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: data.message }]);
        setActiveTranscript(null);
        setIsLoading(false);
        setActivity('idle');

      } else {
        setIsLoading(false);
        setActiveTranscript({ role: 'assistant', content: data.message });
        setActivity('responding');
      }
    });

    return () => {
      socket.off('chat-ready')
      socket.off('chat-response')
    };
  }, [setMessages]);


  const sendMessage = () => {
    if (sessionToDeleteRef.current) {
      socket.emit('delete-session', sessionToDeleteRef.current)
    }
    const newMessage: Message = { role: 'user', content: userInput };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    socket.emit('chat-request', { messages: [...messages, newMessage] });
    setUserInput('')
    setIsLoading(true)
    setActivity('loading');

  }

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  const toggleListening = () => {
    if (isListening) {
      stopListening()
    } else {
      startListening()
    }
  }


  useEffect(() => {
    scrollToBottom();
  }, [messages, activeTranscript]);



  if (showChat) {
    return (
      <div className="flex flex-col h-dvh bg-[#f2ebdc] md:flex-row">
        <Sidebar
          userUUID={userUUID}
          onNewChat={() => {
            setMessages([]);
            sessionToDeleteRef.current = null
          }}
          onSelectSession={async (sessionId) => {
            const messages = await fetchMessagesForSession(sessionId)
            sessionToDeleteRef.current = sessionId
            setMessages(messages)

            // Logic to load the selected session
            // This might involve fetching messages for the session and updating the state
          }}
        />        {/* Main Chat Area */}
        <div className="flex-1 flex flex-col h-screen md:h-screen">
          <main className="flex-grow overflow-hidden flex flex-col px-4 md:px-8 lg:px-16">
            <div className="flex-shrink-0 flex flex-col items-center mb-2 py-2">
              <div className="w-32 h-32 md:w-48 md:h-48 relative flex items-center justify-center">
                <img
                  src={getGifSrc()}
                  alt="AI Intelligence Visualization"
                  className="absolute w-full h-full object-contain scale-110 rounded-full"
                />
              </div>
            </div>
            <div className="flex-grow overflow-y-auto bg-white rounded-lg shadow-md p-4 mb-2">
              {messages.map((message, index) => (
                <ConversationMessage key={index} message={message} index={index} />
              ))}
              {isLoading && <LoadingMessage />}
              {activeTranscript && (
                <div className={`flex mb-6 ${activeTranscript.role === "user" ? "justify-end" : "justify-start"}`}>
                  <div className={`flex items-start space-x-2 max-w-[80%]`}>
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${activeTranscript.role === "user" ? "bg-[#ff6d00]" : "bg-[#0096D6]"} text-white`}>
                      {activeTranscript.role === "user" ? <User className="w-5 h-5" /> : <Bot className="w-5 h-5" />}
                    </div>
                    <div className={`${activeTranscript.role === "user" ? "bg-[#ff6d00]/10" : "bg-white border border-gray-200"} rounded-2xl p-4 shadow-sm`}>
                      <p className="text-sm text-gray-800 whitespace-pre-wrap">{activeTranscript.content}</p>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </main>

          {/* Footer */}
          <footer className="bg-[#f2ebdc] p-2 sm:p-4">
            <div className="container mx-auto flex items-center space-x-2 px-4 md:px-8 lg:px-16">
              <div className="flex-grow mr-2">
                <Input
                  type="text"
                  placeholder="Type your message..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyUp={handleInputKeyPress}
                  className="w-full px-3 py-2 text-base bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-orange-300 focus-visible:ring-orange-300"
                  ref={inputRef}
                />
              </div>
              <div className="flex-shrink-0 flex space-x-2">
                <Button
                  onClick={sendMessage}
                  className="bg-[#ff6d00] hover:bg-[#ff6d00]/80 text-white rounded-full p-2"
                  disabled={!userInput.trim()}
                >
                  <SendHorizontal className="h-5 w-5" />
                  <span className="sr-only">Send message</span>
                </Button>
                {!isListening ? (
                  <Button
                    onClick={startListening}
                    className="rounded-full p-2 bg-[#ff6d00] hover:bg-[#ff6d00]/80 text-white"
                  >
                    <Mic className="h-5 w-5" />
                    <span className="sr-only">Start recording</span>
                  </Button>
                ) : (
                  <Button
                    onClick={stopListening}
                    className="rounded-full p-2 bg-[#ff3900] hover:bg-[#ff3900]/80 text-white"
                  >
                    <StopCircle className="h-5 w-5" />
                    <span className="sr-only">Stop recording</span>
                  </Button>
                )}
              </div>
            </div>
          </footer>
        </div>
        <audio src={audioSrc} className="w-full hidden" ref={audioRef} controls />
      </div>
    );
  } else {
    return (
      <WelcomePage action={handleStart} />
    )
  }

}

export default App;