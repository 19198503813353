import { Message } from "../App";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Bot, User } from 'lucide-react';

interface ConversationMessageProps {
   message: Message;
   index: number
}

export function ConversationMessage({ message, index }: ConversationMessageProps) {
   // Replace \n with actual newlines
   const formattedContent = message.content?.replace(/\\n/g, '\n') || '';

   return (
      <div key={index} className={`flex mb-6 ${message.role === "user" ? "justify-end" : "justify-start"}`}>
         <div className={`flex items-start space-x-2 max-w-[80%]`}>
            <div className={`flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full ${message.role === "user" ? "bg-[#ff6d00]" : "bg-[#0096D6]"} text-white`}>
               {message.role === "user" ? <User className="w-5 h-5" /> : <Bot className="w-5 h-5" />}
            </div>
            <div className={`flex-grow ${message.role === "user" ? "bg-[#ff6d00]/10" : "bg-white border border-gray-200"} rounded-2xl p-4 shadow-sm overflow-hidden`}>
               <Markdown
                  className="markdown-content text-sm text-gray-800 whitespace-pre-wrap"
                  remarkPlugins={[remarkGfm]}
                  components={{
                     h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mb-4" {...props} />,
                     h2: ({ node, ...props }) => <h2 className="text-xl font-bold mb-3" {...props} />,
                     h3: ({ node, ...props }) => <h3 className="text-lg font-bold mb-2" {...props} />,
                     p: ({ node, ...props }) => <p className="mb-4" {...props} />,
                     pre: ({ node, ...props }) => <pre className="mb-4 p-2 bg-gray-100 rounded overflow-x-auto" {...props} />,
                     code: ({ node, ...props }) => <code className="bg-gray-100 rounded px-1" {...props} />,
                     table: ({ node, ...props }) => <table className="mb-4 border-collapse border border-gray-300" {...props} />,
                     th: ({ node, ...props }) => <th className="border border-gray-300 px-4 py-2" {...props} />,
                     td: ({ node, ...props }) => <td className="border border-gray-300 px-4 py-2" {...props} />,
                     blockquote: ({ node, ...props }) => <blockquote className="mb-4 pl-4 border-l-4 border-gray-300 italic" {...props} />,
                     ul: ({ node, ...props }) => <ul className="mb-4 list-disc list-inside" {...props} />,
                     ol: ({ node, ...props }) => <ol className="mb-4 list-decimal list-inside" {...props} />,
                     a: ({ node, ...props }) => <a className="text-blue-500 hover:underline" {...props} />,
                  }}
               >
                  {formattedContent}
               </Markdown>
            </div>
         </div>
      </div>
   );
}